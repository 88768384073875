import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosWithAuth, blobErrorHandler, errorHandler } from "../app/axios";
import { objectToQueryString } from "../helpers/objectToQueryString";
import {
  DeliveryModeType,
  ErrorResponse,
  LoadCartHeader,
  LoadHeaderInput,
  LoadLineInventory,
  OpenOrdersSetup,
  OrderDetailPagedResult,
  OrderTotals,
  ReadyToReleaseSetup,
  ReadyToReleaseTotals,
  SearchCriteria,
  ShippableItemPagedResult,
} from "../types/types";
import { downloadFile } from "../helpers/downloadExcel";

export const fetchReleaseOrdersSelectionData = createAsyncThunk<
  ReadyToReleaseSetup,
  string,
  { rejectValue: string | ErrorResponse }
>(
  "shipping/fetchReleaseOrdersSelectionData",
  async (customerId, { rejectWithValue }) => {
    try {
      const response = await axiosWithAuth.get(
        `${process.env.REACT_APP_API_URL}/shipping/ready_to_release/${customerId}/setup`
      );
      return response.data;
    } catch (error: unknown) {
      return errorHandler(error, rejectWithValue);
    }
  }
);

type FetchReleaseOrdersExcelRequest = {
  customerId: string;
  body: SearchCriteria;
};

export const fetchReleaseOrdersExcel = createAsyncThunk<
  void,
  FetchReleaseOrdersExcelRequest,
  { rejectValue: string | ErrorResponse }
>("shipping/fetchReleaseOrdersExcel", async (params, { rejectWithValue }) => {
  try {
    const query = objectToQueryString(params.body);
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/shipping/ready_to_release/${params.customerId}/excel${query}`,
      {
        responseType: "blob",
      }
    );
    const file = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const filename = response.headers["content-disposition"].split("=")[1];
    downloadFile(file, filename);
    return;
  } catch (error: unknown) {
    return blobErrorHandler(error, rejectWithValue);
  }
});

export const fetchReleaseOrdersPDF = createAsyncThunk<
  void,
  FetchReleaseOrdersExcelRequest,
  { rejectValue: string | ErrorResponse }
>("parts/fetchReleaseOrdersPDF", async (params, { rejectWithValue }) => {
  try {
    const query = objectToQueryString(params.body);
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/shipping/ready_to_release/${params.customerId}/pdf${query}`,
      {
        responseType: "blob",
      }
    );
    const file = new Blob([response.data], {
      type: "application/pdf",
    });
    const filename = response.headers["content-disposition"].split("=")[1];
    downloadFile(file, filename);
    return;
  } catch (error: unknown) {
    return blobErrorHandler(error, rejectWithValue);
  }
});

type FetchReleaseOrdersTotalsRequest = {
  customerId: string;
  criteria?: SearchCriteria;
  requestType: "browse" | "search";
};

export const fetchReleaseOrdersTotals = createAsyncThunk<
  ReadyToReleaseTotals,
  FetchReleaseOrdersTotalsRequest,
  { rejectValue: string | ErrorResponse }
>("shipping/fetchReleaseOrdersTotals", async (params, { rejectWithValue }) => {
  try {
    const query = objectToQueryString(params.criteria);
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/shipping/ready_to_release_site_totals/${params.customerId}${query}`
    );
    const result = response.data;
    result.lastCriteria = params.criteria;
    result.requestType = params.requestType;
    
    return result;
  } catch (error: unknown) {
    return errorHandler(error, rejectWithValue);
  }
});

type FetchReleaseOrdersRequest = {
  customerId: string;
  criteria?: SearchCriteria;
};

export const fetchReleaseOrders = createAsyncThunk<
  ShippableItemPagedResult,
  FetchReleaseOrdersRequest,
  { rejectValue: string | ErrorResponse }
>("shipping/fetchReleaseOrders", async (params, { rejectWithValue }) => {
  try {
    const query = objectToQueryString(params.criteria);
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/shipping/ready_to_release/${params.customerId}${query}`
    );
    return response.data;
  } catch (error: unknown) {
    return errorHandler(error, rejectWithValue);
  }
});

export const fetchOpenOrdersSelectionData = createAsyncThunk<
  OpenOrdersSetup,
  string,
  { rejectValue: string | ErrorResponse }
>(
  "shipping/fetchOpenOrdersSelectionData",
  async (customerId, { rejectWithValue }) => {
    try {
      const response = await axiosWithAuth.get(
        `${process.env.REACT_APP_API_URL}/shipping/open_orders/${customerId}/setup`
      );
      return response.data;
    } catch (error: unknown) {
      return errorHandler(error, rejectWithValue);
    }
  }
);

type FetchOpenOrdersTotalsRequest = {
  customerId: string;
  criteria?: SearchCriteria;
  requestType: "browse" | "search";
};

export const fetchOpenOrdersTotals = createAsyncThunk<
  OrderTotals[],
  FetchOpenOrdersTotalsRequest,
  { rejectValue: string | ErrorResponse }
>("shipping/fetchOpenOrdersTotals", async (params, { rejectWithValue }) => {
  try {
    const query = objectToQueryString(params.criteria);
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/shipping/open_orders_totals/${params.customerId}${query}`
    );
    return response.data;
  } catch (error: unknown) {
    return errorHandler(error, rejectWithValue);
  }
});

type FetchOpenOrdersRequest = {
  customerId: string;
  criteria?: SearchCriteria;
};

export const fetchOpenOrders = createAsyncThunk<
  OrderDetailPagedResult,
  FetchOpenOrdersRequest,
  { rejectValue: string | ErrorResponse }
>("shipping/fetchOpenOrders", async (params, { rejectWithValue }) => {
  try {
    const query = objectToQueryString(params.criteria);
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/shipping/open_orders/${params.customerId}${query}`
    );
    return response.data;
  } catch (error: unknown) {
    return errorHandler(error, rejectWithValue);
  }
});

type FetchOpenOrdersExcelRequest = {
  customerId: string;
  body?: SearchCriteria;
};

export const fetchOpenOrdersExcel = createAsyncThunk<
  void,
  FetchOpenOrdersExcelRequest,
  { rejectValue: string | ErrorResponse }
>("shipping/fetchOpenOrdersExcel", async (params, { rejectWithValue }) => {
  try {
    const query = objectToQueryString(params.body);
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/shipping/open_orders/${params.customerId}/excel${query}`,
      {
        responseType: "blob",
      }
    );
    const file = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const filename = response.headers["content-disposition"].split("=")[1];
    downloadFile(file, filename);
    return;
  } catch (error: unknown) {
    return blobErrorHandler(error, rejectWithValue);
  }
});

export const fetchOpenOrdersPDF = createAsyncThunk<
  void,
  FetchReleaseOrdersExcelRequest,
  { rejectValue: string | ErrorResponse }
>("parts/fetchOpenOrdersPDF", async (params, { rejectWithValue }) => {
  try {
    const query = objectToQueryString(params.body);
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/shipping/open_orders/${params.customerId}/pdf${query}`,
      {
        responseType: "blob",
      }
    );
    const file = new Blob([response.data], {
      type: "application/pdf",
    });
    const filename = response.headers["content-disposition"].split("=")[1];
    downloadFile(file, filename);
    return;
  } catch (error: unknown) {
    return blobErrorHandler(error, rejectWithValue);
  }
});

export const fetchAllOpenOrdersExcel = createAsyncThunk<
  void,
  void,
  { rejectValue: string | ErrorResponse }
>("shipping/fetchOpenOrdersExcel", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/internal/order/open_orders/excel`,
      {
        responseType: "blob",
      }
    );
    const file = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const filename = response.headers["content-disposition"].split("=")[1];
    downloadFile(file, filename);
    return;
  } catch (error: unknown) {
    return blobErrorHandler(error, rejectWithValue);
  }
});

type FetchOpenOrderPartialSoftAllocationReportRequest = {
  includeAll?: boolean;
};

export const fetchOpenOrderPartialSoftAllocation = createAsyncThunk<
  void,
  FetchOpenOrderPartialSoftAllocationReportRequest,
  { rejectValue: string | ErrorResponse }
>(
  "shipping/fetchOpenOrderPartialSoftAllocationReport",
  async (includeAll, { rejectWithValue }) => {
    try {
      const query = objectToQueryString(includeAll);
      const response = await axiosWithAuth.get(
        `${process.env.REACT_APP_API_URL}/internal/order/partial_soft_allocations/excel${query}`,
        {
          responseType: "blob",
        }
      );
      const file = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const filename = response.headers["content-disposition"].split("=")[1];
      downloadFile(file, filename);
      return;
    } catch (error: unknown) {
      return blobErrorHandler(error, rejectWithValue);
    }
  }
);

export const fetchLoadCart = createAsyncThunk<
  LoadCartHeader,
  string,
  { rejectValue: string | ErrorResponse }
>("loads/fetchLoadCart", async (customerId, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/release/${customerId}`
    );
    return response.data;
  } catch (error: unknown) {
    return errorHandler(error, rejectWithValue);
  }
});

type AddItemToLoadRequest = {
  customerId: string;
  body: {
    allBundles?: boolean;
    inventoryTransactionId: string;
    loadLineBundles?: {
      tag?: string;
      piecesPerBundle: number;
    }[];
  };
};

export const addItemToLoad = createAsyncThunk<
  LoadCartHeader,
  AddItemToLoadRequest,
  { rejectValue: string | ErrorResponse }
>("loads/addItemToLoadRequest", async (params, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.post(
      `${process.env.REACT_APP_API_URL}/release/${params.customerId}/item`,
      params.body
    );
    return response.data;
  } catch (error: unknown) {
    return errorHandler(error, rejectWithValue);
  }
});

type UpdateLoadCartItemRequest = {
  customerId: string;
  id: number;
  body: {
    inventoryTransactionId: string;
    allBundles?: boolean;
    loadLineBundles?: {
      tag?: string;
      piecesPerBundle: number;
    }[];
  };
};

export const updateLoadCartItem = createAsyncThunk<
  LoadCartHeader,
  UpdateLoadCartItemRequest,
  { rejectValue: string | ErrorResponse }
>("loads/updateLoadCartItem", async (params, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.put(
      `${process.env.REACT_APP_API_URL}/release/${params.customerId}/item/${params.id}`,
      params.body
    );
    return response.data;
  } catch (error: unknown) {
    return errorHandler(error, rejectWithValue);
  }
});

type UpdateLoadCartRequest = {
  customerId: string;
  body: LoadHeaderInput;
};

export const updateLoadCart = createAsyncThunk<
  LoadCartHeader,
  UpdateLoadCartRequest,
  { rejectValue: string | ErrorResponse }
>("loads/updateLoadCart", async (params, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.put(
      `${process.env.REACT_APP_API_URL}/release/${params.customerId}`,
      params.body
    );
    return response.data;
  } catch (error: unknown) {
    return errorHandler(error, rejectWithValue);
  }
});

export const submitLoadCart = createAsyncThunk<
  LoadCartHeader,
  UpdateLoadCartRequest,
  { rejectValue: string | ErrorResponse }
>("loads/submitLoadCart", async (params, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.put(
      `${process.env.REACT_APP_API_URL}/release/${params.customerId}/submit`,
      params.body
    );
    return response.data;
  } catch (error: unknown) {
    return errorHandler(error, rejectWithValue);
  }
});

type ChangeDeliveryModeRequest = {
  customerId: string;
  body: {
    deliveryMode: DeliveryModeType;
  };
};

export const changeDeliveryMode = createAsyncThunk<
  LoadCartHeader,
  ChangeDeliveryModeRequest,
  { rejectValue: string | ErrorResponse }
>("loads/changeDeliveryMode", async (params, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.put(
      `${process.env.REACT_APP_API_URL}/release/${params.customerId}/change_delivery_mode`,
      params.body
    );
    return response.data;
  } catch (error: unknown) {
    return errorHandler(error, rejectWithValue);
  }
});

type AutoLoadShipmentRequest = {
  customerId: string;
  shipToId: string;
  criteria: SearchCriteria;
};

export const autoLoadShipment = createAsyncThunk<
  LoadCartHeader,
  AutoLoadShipmentRequest,
  { rejectValue: string | ErrorResponse }
>("loads/autoLoadShipment", async (params, { rejectWithValue }) => {
  try {
    const query = objectToQueryString({
      shipToId: params.shipToId,
      ...params.criteria,
    });
    const response = await axiosWithAuth.put(
      `${process.env.REACT_APP_API_URL}/release/${params.customerId}/autoload${query}`
    );
    return response.data;
  } catch (error: unknown) {
    return errorHandler(error, rejectWithValue);
  }
});

export const clearLoadCart = createAsyncThunk<
  LoadCartHeader,
  string,
  { rejectValue: string | ErrorResponse }
>("loads/clearLoadCart", async (customerId, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.delete(
      `${process.env.REACT_APP_API_URL}/release/${customerId}`
    );
    return response.data;
  } catch (error: unknown) {
    return errorHandler(error, rejectWithValue);
  }
});

type RemoveLoadCartItemRequest = {
  customerId: string;
  id: number;
};

export const removeLoadCartItem = createAsyncThunk<
  LoadCartHeader,
  RemoveLoadCartItemRequest,
  { rejectValue: string | ErrorResponse }
>("loads/removeLoadCartItem", async (params, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.delete(
      `${process.env.REACT_APP_API_URL}/release/${params.customerId}/item/${params.id}`
    );
    return response.data;
  } catch (error: unknown) {
    return errorHandler(error, rejectWithValue);
  }
});

export type GetBundlesForOrderDetailRequest = {
  customerId: string;
  id?: number;
  inventoryTransactionId: string;
};

export const getBundlesForOrderDetail = createAsyncThunk<
  LoadLineInventory,
  GetBundlesForOrderDetailRequest,
  { rejectValue: string | ErrorResponse }
>("shipping/getBundlesForOrderDetail", async (params, { rejectWithValue }) => {
  try {
    const query = objectToQueryString({
      id: params.id ?? undefined,
      inventoryTransactionId: params.inventoryTransactionId,
    });
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/shipping/ready_to_release/${params.customerId}/bundles${query}`
    );
    return response.data;
  } catch (error: unknown) {
    return errorHandler(error, rejectWithValue);
  }
});
