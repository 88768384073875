/** @jsxImportSource @emotion/react */
import React, { ReactNode, useEffect, useRef, useState } from "react";
import tw from "twin.macro";
import TextButton from "../../../../components/atoms/TextButton";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  fetchCustomerPartDetails,
  fetchCustomerPartsExcel,
  fetchCustomerPartsPDF,
} from "../../../../store/parts.actions";
import { selectCurrentCustomerId } from "../../../../store/customer.reducer";
import PartListTable from "./PartListTable";
import AnimatedLoadingSpinner from "../../../../components/atoms/AnimatedLoadingSpinner";
import HistoricalPriceModal from "../../../../components/molecules/HistoricalPriceModal";
import { fetchHistoricalPriceSheetsExcel } from "../../../../store/document.actions";
import { selectCurrentAuthorities } from "../../../../store/user.reducer";
import { hasAuthority } from "../../../../helpers/hasAuthority";
import { CustomerPart, CustomerPartDetail, ErrorResponse, ProductSelectionDetails } from "../../../../types/types";
import PartDescriptionModal from "../../../../components/molecules/PartDescriptionModal";
import { selectCurrentCustomerParts } from "../../../../store/parts.reducer";
import HorizontalDimensionSelection, { DimensionsSelectRefType } from "../../../../components/organisms/HorizontalDimensionSelection";
import Button from "../../../../components/atoms/Button";
import Toast from "../../../../components/molecules/Toast";


type Props = {
  children?: ReactNode;
};

const Parts: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const currentAuthorities = useAppSelector(selectCurrentAuthorities);
  const currentCustomerParts = useAppSelector(selectCurrentCustomerParts);
  const customerId = useAppSelector(selectCurrentCustomerId);
  const { docStatus } = useAppSelector((state) => state.parts);

  const [showHistoricalPricesModal, setShowHistoricalPricesModal] =
    useState(false);
  const [selectedPart, setSelectedPart] = useState<CustomerPart>();
  const { detailStatus } = useAppSelector((state) => state.parts);
  

  const [results, setResults] = useState<CustomerPartDetail[]>([]);
  
  const [excelFlag, setExcelFlag] = useState(false);
  const [pdfFlag, setPdfFlag] = useState(false);
  const productSelectionRef = useRef<DimensionsSelectRefType>(null);
  const [dimensions, setDimensions] = useState<ProductSelectionDetails>({
    height: 0,
    width: 0,
    wall: 0,
    grade: "",
    shape: "Unknown",
  });
  const [error, setError] = useState<ErrorResponse | string>();

  const getProductParams = () => {
    const product = {
      grade: dimensions.grade === "" ? undefined : dimensions?.grade,
      shape: dimensions.shape === "Unknown" ? undefined : dimensions?.shape,
      dimension1: dimensions.width === 0 ? undefined : dimensions?.width,
      dimension2: dimensions.height === 0 ? undefined : dimensions?.height,
      nominalWallThickness:
        dimensions?.wall === 0 ? undefined : dimensions?.wall,
    };
    return product;
  };

  const searchHandler = () => {
    if (!customerId) return; 
    const params = getProductParams();
   
    if (excelFlag) {
      dispatch(fetchCustomerPartsExcel({ customerId, ...params }))
      .unwrap()
      .catch((error) => setError(error));
    } else if (pdfFlag) {
      dispatch(fetchCustomerPartsPDF({ customerId, ...params }))
      .unwrap()
      .catch((error) => setError(error));
    } else {
      setResults([]);
      dispatch(fetchCustomerPartDetails({ customerId, ...params }))
        .unwrap()
        .then((parts) => setResults(parts))
        .catch((error) => setError(error));
    }
  };

  useEffect(() => {
    //users don't want the criteria reset
    //productSelectionRef.current?.reset();

    if (!customerId) return;

    setExcelFlag(false);
    setPdfFlag(false);

    const params = getProductParams();

    setResults([]);
    dispatch(fetchCustomerPartDetails({ customerId, ...params }))
      .unwrap()
      .then((parts) => setResults(parts))
      .catch((error) => setError(error));
  }, [customerId]);

  const isInternal = hasAuthority(["internal"], currentAuthorities);

  return (
    <div css={tw`p-2 text-xs w-full`}>
      {error && (
        <Toast
          message={error}
          type="error"
          onConfirm={() => setError(undefined)}
        />
      )}
      {selectedPart && (
        <PartDescriptionModal
          onCancel={() => setSelectedPart(undefined)}
          details={selectedPart}
        />
      )}
      {showHistoricalPricesModal && (
        <HistoricalPriceModal
          checkShipToPricing={false}
          action={fetchHistoricalPriceSheetsExcel}
          onCancel={() => setShowHistoricalPricesModal(false)}
        />
      )}
      <div
        css={tw`text-white bg-nucor-green w-full px-2 py-1 flex gap-4 items-center`}
      >
        <h3 css={tw`uppercase font-semibold`}>Customer Part List</h3>
        {customerId && isInternal && (
          <TextButton
            onClick={() => setShowHistoricalPricesModal(true)}
            css={tw`text-white`}
          >
            Historical Part Prices
          </TextButton>
        )}
      </div>
      <div css={tw`flex p-2 bg-gradient-to-b from-[#e6e6e6] via-[#fdfdfd] to-[#e6e6e6] gap-1 text-xs items-center`}>
        <HorizontalDimensionSelection
          ref={productSelectionRef}
          onChange={(value) => setDimensions(value)}
        />
        <span css={tw`ml-4`}>Run as Excel:</span>
        <input
          type="checkbox"
          name="excel"
          checked={excelFlag}
          onChange={(e) => {
            setPdfFlag(false);
            setExcelFlag(e.target.checked);
          }}
        />
        {customerId && (
          <>
            <span css={tw`ml-4`}>Run as PDF:</span>
            <input
              type="checkbox"
              name="pdf"
              checked={pdfFlag}
              onChange={(e) => {
                setExcelFlag(false);
                setPdfFlag(e.target.checked);
              }}
            />
          </>
        )}
        <Button type="button" css={tw`ml-4`} disabled={ docStatus == "pending" || detailStatus === "pending"} onClick={searchHandler}>Search</Button>

        { docStatus == "pending" && excelFlag && (
          <AnimatedLoadingSpinner message="Loading Excel..." css={tw`ml-4`} />
        )}
        { docStatus == "pending" && pdfFlag && (
          <AnimatedLoadingSpinner message="Loading PDF..." css={tw`ml-4`} />
        )}
      </div>
      <PartListTable
        results={results}
        onSelect={(selectedPart: CustomerPartDetail) => {
          const part = currentCustomerParts?.find(
            (item) =>
              item.customerPartNumber === selectedPart.customerPartNumber
          );
          setSelectedPart(part);
        }}
      />
    </div>
  );
};

export default Parts;
