/** @jsxImportSource @emotion/react */
import React, { ReactNode, useContext, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import ButtonSelect from "../../../../components/atoms/ButtonSelect";
import Select from "../../../../components/atoms/Select";
import TabGroupSubGroup from "../../../../components/atoms/TabGroupSubGroup";
import { selectCurrentCustomerId } from "../../../../store/customer.reducer";
import {
  clearDocumentErrors,
  selectPackingSlipSelectionData,
  updatePackingSlipSearchCriteria,
} from "../../../../store/document.reducer";
import { selectDefaultMill } from "../../../../store/user.reducer";
import { searchPackingSlips } from "../../../../store/document.actions";
import Toast from "../../../../components/molecules/Toast";
import { PDFContext } from "./BillsOfLading";
import { SearchCriteria } from "../../../../types/types";

type Props = {
  children?: ReactNode;
};

export type PackingSlipBrowseCriteria = {
  myCustomers?: boolean;
  shipToCustomerId?: string;
  dateRangeType?: string;
  deliveryMode?: string;
  siteKey?: string;
  sites?: string[];
};

const BillsOfLadingBrowse: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const ctx = useContext(PDFContext);
  const customerId = useAppSelector(selectCurrentCustomerId);
  const defaultMill = useAppSelector(selectDefaultMill);
  const { searchCriteria, error } = useAppSelector(
    (state) => state.document.packingSlip
  );
  const packingSlipSelectionData = useAppSelector(
    selectPackingSlipSelectionData
  );

  const getSites = (siteKey: string | undefined) : string[] | undefined => {
    if (!siteKey || !packingSlipSelectionData) {
      return undefined;
    }
    return siteKey === "ALL" ? undefined : packingSlipSelectionData.sites.find((item) => item.key === siteKey)?.sites;
  };

  const getBrowseCriteria = () : PackingSlipBrowseCriteria  => {
    const myCustomers = searchCriteria.myCustomers ?? false;
    const shipToCustomerId = searchCriteria.shipToCustomerId ?? "all";
    const dateRangeType = searchCriteria.dateRangeType ?? "last30Days";
    const deliveryMode = searchCriteria.deliveryMode ?? "all";
    const siteKey = searchCriteria.siteKey ?? defaultMill ?? "ALL"
    
    const criteria = {myCustomers, shipToCustomerId, dateRangeType, deliveryMode, siteKey};
  
    return criteria;
  };

  const getQueryCriteria = (browseCriteria: PackingSlipBrowseCriteria) : SearchCriteria => {
    const {myCustomers, shipToCustomerId, dateRangeType, deliveryMode, siteKey} = browseCriteria;

    const criteria = {
      ...searchCriteria,
      myCustomers: myCustomers,
      shipToCustomerId:
        shipToCustomerId === "all" || myCustomers
          ? undefined
          : shipToCustomerId,
      dateRangeType,
      deliveryMode: deliveryMode === "all" ? undefined : deliveryMode,
      siteKey: siteKey,
      sites: getSites(siteKey)
    };

    return criteria;
  };

  const browseCriteria = getBrowseCriteria();
  const {myCustomers, shipToCustomerId, dateRangeType, deliveryMode, siteKey} = browseCriteria;
  const criteria = getQueryCriteria(browseCriteria);

  const shipTos =
    packingSlipSelectionData?.shipTos.map((shipTo) => {
      return {
        value: shipTo.id,
        label: <span>{`${shipTo.id} - ${shipTo.name}`}</span>,
      };
    }) ?? [];

  const deliveryModes =
    packingSlipSelectionData?.deliveryModes.map((item) => ({
      value: item.id,
      label: item.description,
    })) ?? [];

  const getScreenUpdateCriteria = (criteria: SearchCriteria) => {
    const c = {
      ...criteria,
      shipToCustomerId: criteria.myCustomers ? "all" : criteria.shipToCustomerId
    };

    return c;
  };

  const getSearchUpdateCriteria = (criteria: SearchCriteria) => {
    const c = {
      ...criteria,
      shipToCustomerId: criteria.myCustomers || criteria.shipToCustomerId === "all" ? undefined : criteria.shipToCustomerId
    };

    return c;
  };

  const search = (criteria: SearchCriteria) => {
    dispatch(updatePackingSlipSearchCriteria({ ...getScreenUpdateCriteria(criteria), page: 1}));
    dispatch(searchPackingSlips({customerId, criteria: { ...getSearchUpdateCriteria(criteria), siteKey: undefined }}));
  };

  useEffect(() => {
    if (packingSlipSelectionData) {
      const criteria = getQueryCriteria(getBrowseCriteria());
      search(criteria);
    }
  }, [packingSlipSelectionData]);
    
  const onSelectionsChanged = (criteria: PackingSlipBrowseCriteria) => {
    // Close pdf if open
    if (ctx?.file) {
      ctx?.setFile(null);
    }
    search(criteria);
  };

  return (
    <>
      {error && (
        <Toast
          type="error"
          message={error}
          onConfirm={() => dispatch(clearDocumentErrors())}
        />
      )}
      <TabGroupSubGroup title="Show">
        <Select
          minWidth="22ch"
          name="showBy"
          data={packingSlipSelectionData?.customerSearchType.map((item) => ({
            value: item.id,
            label: item.description,
          }))}
          onChange={(value: boolean) => {
            onSelectionsChanged({
              ...criteria,
              myCustomers: value,
            });
          }}
          value={myCustomers}
        />
      </TabGroupSubGroup>
      {!myCustomers && (
        <TabGroupSubGroup title="By Ship To">
          <Select
            minWidth="22ch"
            maxWidth="22ch"
            name="byShipTo"
            data={[{ value: "all", label: "All" }, ...shipTos]}
            onChange={(value: string) => {
              onSelectionsChanged({
                ...criteria,
                shipToCustomerId: value === "all" ? undefined : value,
              });
            }}
            value={shipToCustomerId}
          />
        </TabGroupSubGroup>
      )}
      <TabGroupSubGroup title="By Delivery Mode">
        <Select
          name="deliveryMode"
          minWidth="22ch"
          data={[{ value: "all", label: "All" }, ...deliveryModes]}
          value={deliveryMode}
          onChange={(value: string) => {
            onSelectionsChanged({
              ...criteria,
              deliveryMode: value === "all" ? undefined : value,
            });
          }}
        />
      </TabGroupSubGroup>
      <TabGroupSubGroup title="By Ship Date">
        {packingSlipSelectionData?.dateSelections.map((date, index) => {
          return (
            <ButtonSelect
              key={index}
              onClick={() => {
                onSelectionsChanged({
                  ...criteria,
                  dateRangeType: date.id,
                });
              }}
              isActive={dateRangeType === date.id}
            >
              {date.description}
            </ButtonSelect>
          );
        })}
      </TabGroupSubGroup>
      <TabGroupSubGroup title="By Mill">
        <ButtonSelect
          isActive={siteKey === "ALL"}
          onClick={() => {
            onSelectionsChanged({
              ...criteria,
              siteKey: "ALL",
              sites: undefined,
            });
          }}
        >
          All
        </ButtonSelect>
        {packingSlipSelectionData?.sites.map((item, index) => {
          return (
            <ButtonSelect
              isActive={item.key === siteKey}
              onClick={() => {
                onSelectionsChanged({
                  ...criteria,
                  siteKey: item.key,
                  sites: getSites(item.key),
                });
              }}
              key={index}
            >
              {item.description}
            </ButtonSelect>
          );
        })}
      </TabGroupSubGroup>
    </>
  );
};

export default BillsOfLadingBrowse;
